import s from "assets/scss/Contact.module.scss"
import Img from "components/Img"

import axios from "axios"
import cx from "classnames"
import ButtonCircle from "components/ButtonCircle"
import IconArrow from "components/Icon/IconArrow"
import { FormEvent, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"

import charHi from "assets/img/char-hi.svg"
import { api } from "typess"
import PrivacyPolicy from "components/PrivacyPolicy"
import { useFeedbackStore } from "store/feedBackStore"

const Contact = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const messageInputRef = useRef<HTMLTextAreaElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const [name, setName] = useState<string | null>(null)
  const [phone, setPhone] = useState<string | null>(null)
  const [email, setEmail] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const [confirmed, setConfirmed] = useState<boolean>(false)

  const feedbackStore = useFeedbackStore()

  // const [response, setResponse] = useState<{
  //   message: string | null
  //   success: boolean | null
  // } | null>(null)
  // const [responsed, setResponsed] = useState(false)

  const { i18n, t } = useTranslation()

  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/iletisim")
    } else {
      navigate("/contact")
    }
  }, [i18n.language])

  const handleConfirmation = () => {
    setConfirmed((prev) => !prev)
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await axios.post<any>(
        `${api}contact.php`,
        { name, phone, email, message, language: i18n.language },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )

      feedbackStore.toggleFeedback()
      feedbackStore.setText(data.message)
      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  const handleFocus = (e: FormEvent | any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.3"
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("titleAndDesc.contact.title")}</title>
        <meta name="description" content={`${t("titleAndDesc.contact.desc")}`}></meta>
        <link rel="canonical" href={`${t("titleAndDesc.contact.canonical")}`} />
      </Helmet>
      <main className={s.contact}>
        <section className={s.intro}>
          <div className={s.titleW}>
            <h1 className={s.title}>{t("contact.title.p1")}</h1>
            <h1 className={s.title}>
              <Trans i18nKey="contact.title.p2" components={{ br: <br /> }}>
                {t("contact.title.p2")}
              </Trans>
            </h1>
          </div>
          <div className={s.contactInfoW}>
            <div className={s.contactInfo}>
              <div className={cx(s.part, s.address)}>
                <div className={s.itemW}>
                  <label className={s.label}>{t("contact.addressLabels.address")}</label>
                  <p className={cx(s.item, s.address)}>
                    Ege Mahallesi Havalimanı Başmüdürlüğü Küme Evleri No:27 Dalaman/MUĞLA
                  </p>
                </div>
              </div>
              <div className={cx(s.part, s.emailPhone)}>
                <div className={s.itemW}>
                  <label className={s.label}>{t("contact.addressLabels.email")}</label>
                  <a href="mailto:info@paterna.com" className={cx(s.item, s.email)}>
                    info@paterna.com
                  </a>
                </div>
                <div className={s.itemW}>
                  <label className={s.label}>{t("contact.addressLabels.phone")}</label>
                  <a href="tel:+902527925083" className={cx(s.item, s.phone)}>
                    +90 252 792 50 83
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            href="https://www.google.com/search?q=Ege%20Mahallesi%20Havaliman%C4%B1%20Ba%C5%9Fm%C3%BCd%C3%BCrl%C3%BC%C4%9F%C3%BC%20K%C3%BCme%20Evleri%20No%3A27%20Dalaman%2FMU%C4%9ELA&rlz=1C5CHFA_enTR995TR995&oq=Ege+Mahallesi+Havaliman%C4%B1+Ba%C5%9Fm%C3%BCd%C3%BCrl%C3%BC%C4%9F%C3%BC+K%C3%BCme+Evleri+No%3A27+Dalaman%2FMU%C4%9ELA&aqs=chrome.0.69i59.222j0j15&sourceid=chrome&ie=UTF-8&tbs=lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=14623828723745088730&lqi=Ck5FZ2UgTWFoYWxsZXNpIEhhdmFsaW1hbsSxIEJhxZ9tw7xkw7xybMO8xJ_DvCBLw7xtZSBFdmxlcmkgTm86MjcgRGFsYW1hbi9NVcSeTEFIq5ekI1psEAAQARACEAMQBBAFEAYQBxgAGAIYCBgJIk5lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcgZGFsYW1hbiBtdcSfbGEyAnRykgEaY2l2aWxpYW5fcGFzc2VuZ2VyX2FpcnBvcnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUjFhWE5VZVhaQlJSQUKqAUcQASpDIj9lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcoIQ&ved=2ahUKEwjS-Mn5s638AhWaSvEDHa3uAyAQvS56BAgOEAE&sa=X&rlst=f#rlfi=hd:;si:14623828723745088730,l,Ck5FZ2UgTWFoYWxsZXNpIEhhdmFsaW1hbsSxIEJhxZ9tw7xkw7xybMO8xJ_DvCBLw7xtZSBFdmxlcmkgTm86MjcgRGFsYW1hbi9NVcSeTEFIq5ekI1psEAAQARACEAMQBBAFEAYQBxgAGAIYCBgJIk5lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcgZGFsYW1hbiBtdcSfbGEyAnRykgEaY2l2aWxpYW5fcGFzc2VuZ2VyX2FpcnBvcnSaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUjFhWE5VZVhaQlJSQUKqAUcQASpDIj9lZ2UgbWFoYWxsZXNpIGhhdmFsaW1hbsSxIGJhxZ9tw7xkw7xybMO8xJ_DvCBrw7xtZSBldmxlcmkgbm8gMjcoIQ;mv:[[36.7693083,28.801511299999998],[36.714477099999996,28.785583199999994]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!1m4!1u2!2m2!2m1!1e1!2m1!1e2!2m1!1e3!3sIAE,lf:1,lf_ui:2"
            target="_blank"
            rel="noreferrer"
            className={s.btnW}
          >
            <ButtonCircle
              text={{ part1: t("contact.btnText.p1"), part2: t("contact.btnText.p2") }}
              icon={<IconArrow fill="#2b2b2b" scale={0.8} />}
            ></ButtonCircle>
          </a>
        </section>
        <section className={s.contactForm}>
          <form className={s.form} ref={formRef} onSubmit={handleSubmit}>
            <div className={s.row}>
              <div className={s.inputW}>
                <small className={s.smallTop}>CONTACT FORM</small>
                <label
                  className={cx(s.label, {
                    [s.hidden]: name,
                  })}
                  htmlFor="name"
                >
                  {t("contact.form.name")}
                </label>
                <input
                  className={s.input}
                  id="name"
                  type="text"
                  ref={nameInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setName(e.currentTarget.value)
                  }}
                  required
                />
              </div>
            </div>

            <div className={s.row}>
              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: phone,
                  })}
                  htmlFor="phone"
                >
                  {t("contact.form.phone")}
                </label>
                <input
                  className={s.input}
                  id="phone"
                  type="tel"
                  ref={phoneInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setPhone(e.currentTarget.value)
                  }}
                  onInput={(e: any) =>
                    (e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"))
                  }
                />
              </div>

              <div className={s.inputW}>
                <label
                  className={cx(s.label, {
                    [s.hidden]: email,
                  })}
                  htmlFor="email"
                >
                  {t("contact.form.email")}
                </label>
                <input
                  className={s.input}
                  id="email"
                  type="email"
                  ref={emailInputRef}
                  onFocus={handleFocus}
                  onBlur={handleFocus}
                  onChange={(e) => {
                    setEmail(e.currentTarget.value)
                  }}
                  required
                />
              </div>
            </div>

            <div className={s.row}>
              <div className={s.textareaW}>
                <label className={s.label} htmlFor="message">
                  {t("contact.form.message")}
                </label>
                <textarea
                  className={s.textarea}
                  id="message"
                  ref={messageInputRef}
                  onChange={(e) => {
                    setMessage(e.currentTarget.value)
                  }}
                  required
                />
              </div>
            </div>

            <div className={s.row}>
              <div className={s.confirmation}>
                <div className={s.checkbox} onClick={handleConfirmation}>
                  <div className={cx(s.inner, { [s.enabled]: confirmed })}></div>
                </div>
                <PrivacyPolicy setConfirmation={setConfirmed} path={t("contact.personalDataInfo.path")} />
              </div>
            </div>

            <button className={cx(s.sendBtn, { [s.enabled]: confirmed })}>
              <p className={s.btnText}>{t("contact.form.btnText")}</p>
            </button>
          </form>
          <div className={s.stickers}>
            <div className={cx(s.sticker, s.stickerAsk)}>
              <h2 className={s.text}>{t("contact.stickers.ask")}</h2>
              <span className={s.imgW}>
                <Img src={charHi} objectFit="contain"></Img>
              </span>
            </div>
            <div className={cx(s.sticker, s.stickerContact)}>
              <h2 className={s.text}>{t("contact.stickers.contact")}</h2>
              <span className={s.imgW}>
                <Img src={charHi} objectFit="contain"></Img>
              </span>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default Contact

import s from "assets/scss/FieldsOfActivity.module.scss"

import cx from "classnames"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { Helmet } from "react-helmet"

import Img from "components/Img"

import charShopping from "assets/img/char-shopping-with-bg.svg"
import charHotdog from "assets/img/char-hotdog-with-bg.svg"
import stickerExperience from "assets/img/sticker-experience.png"
import stickerExplore from "assets/img/sticker-explore.png"

import ladyEating from "assets/img/happy-african-american-woman-office-worker-eating.png"
import ladyBackpack from "assets/img/back-view-of-woman-tourist-with-backpack.png"
import ExploreOurBrands from "components/ExploreOurBrands"
import { Trans, useTranslation } from "react-i18next"

const FieldsOfActivity = () => {
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/faaliyet-alanlari")
    } else {
      navigate("/fields-of-activity")
    }
  }, [i18n.language])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("titleAndDesc.fieldsOfActivity.title")}</title>
        <meta name="description" content={`${t("titleAndDesc.fieldsOfActivity.desc")}`}></meta>
        <link rel="canonical" href={`${t("titleAndDesc.fieldsOfActivity.canonical")}`} />
      </Helmet>
      <main className={s.fieldsOfActivity}>
        <section className={s.intro}>
          <div className={s.titleW}>
            <h1 className={s.title}>{t("fieldsOfActivity.title.p1")}</h1>
            <h1 className={s.title}>{t("fieldsOfActivity.title.p2")}</h1>
            <p className={s.desc}>{t("fieldsOfActivity.desc")}</p>
          </div>
          <div className={s.imgsW}>
            <div className={s.imgW}>
              <Img src={charShopping} objectFit="contain"></Img>
            </div>
            <div className={s.imgW}>
              <Img src={charHotdog} objectFit="contain"></Img>
            </div>
          </div>
        </section>
        <section className={s.fields}>
          <div className={cx(s.row, s.right)}>
            <div className={s.imgW} data-scaling-img-wr>
              <div data-scaling-img>
                <Img src={ladyEating} objectFit="cover"></Img>
              </div>
            </div>
            <div className={s.fieldCard}>
              <small className={s.index}>1</small>
              <div className={s.cardContent}>
                <h3 className={s.cardTitle}>
                  <Trans i18nKey="fieldsOfActivity.sections.s1.title" components={{ br: <br /> }}>
                    {t("fieldsOfActivity.sections.s1.title")}
                  </Trans>
                </h3>
                <p className={s.par}>{t("fieldsOfActivity.sections.s1.text.p1")}</p>
                <p className={s.par}>{t("fieldsOfActivity.sections.s1.text.p2")}</p>
              </div>
            </div>
          </div>
          <div className={cx(s.row, s.left)}>
            <div className={s.fieldCard}>
              <small className={s.index}>2</small>
              <div className={s.cardContent}>
                <h3 className={s.cardTitle}>
                  <Trans i18nKey="fieldsOfActivity.sections.s2.title" components={{ br: <br /> }}>
                    {t("fieldsOfActivity.sections.s2.title")}
                  </Trans>
                </h3>
                <p className={s.par}>{t("fieldsOfActivity.sections.s2.text.p1")}</p>
              </div>
            </div>
          </div>
          <div className={cx(s.row, s.right)}>
            <div className={s.imgW} data-scaling-img-wr>
              <div data-scaling-img>
                <Img src={ladyBackpack} objectFit="cover"></Img>
              </div>
            </div>
            <div className={s.fieldCard}>
              <small className={s.index}>3</small>
              <div className={s.cardContent}>
                <h3 className={s.cardTitle}>
                  <Trans i18nKey="fieldsOfActivity.sections.s3.title" components={{ br: <br /> }}>
                    {t("fieldsOfActivity.sections.s3.title")}
                  </Trans>
                </h3>
                <p className={s.par}>{t("fieldsOfActivity.sections.s3.text.p1")}</p>
                <p className={s.par}>{t("fieldsOfActivity.sections.s3.text.p1")}</p>
              </div>
            </div>
          </div>
          <div className={cx(s.row, s.left)}>
            <div className={s.fieldCard}>
              <small className={s.index}>4</small>
              <div className={s.cardContent}>
                <h3 className={s.cardTitle}>
                  <Trans i18nKey="fieldsOfActivity.sections.s4.title" components={{ br: <br /> }}>
                    {t("fieldsOfActivity.sections.s4.title")}
                  </Trans>
                </h3>
                <p className={s.par}>{t("fieldsOfActivity.sections.s4.text.p1")}</p>
              </div>
            </div>
          </div>
          <div className={s.stickers}>
            <div className={s.stickerW}>
              <Img src={stickerExperience} objectFit="contain"></Img>
            </div>
            <div className={s.stickerW}>
              <Img src={stickerExplore} objectFit="contain"></Img>
            </div>
          </div>
        </section>
        <ExploreOurBrands />
      </main>
    </>
  )
}

export default FieldsOfActivity

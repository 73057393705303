import s from "assets/scss/Career.module.scss"
import { FormEvent, useEffect, useRef, useState } from "react"

import axios from "axios"
import cx from "classnames"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import iconFile from "assets/icon/icon-file.svg"
import charCareer from "assets/img/char-career.svg"
import Img from "components/Img"
import PrivacyPolicy from "components/PrivacyPolicy"
import { useFeedbackStore } from "store/feedBackStore"
import { api } from "typess"

const Career = () => {
  const nameInputRef = useRef<HTMLInputElement>(null)
  const emailInputRef = useRef<HTMLInputElement>(null)
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const resumeInputRef = useRef<HTMLInputElement>(null)
  const messageInputRef = useRef<HTMLTextAreaElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  const [name, setName] = useState<any>(null)
  const [phone, setPhone] = useState<any>(null)
  const [email, setEmail] = useState<any>(null)
  const [message, setMessage] = useState<any>(null)
  const [resume, setResume] = useState<any>(null)
  const [resumeFileName, setResumeFileName] = useState<string | null>(null)

  const [confirmed, setConfirmed] = useState<boolean>(false)

  const feedbackStore = useFeedbackStore()
  const { t, i18n } = useTranslation()

  const navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/kariyer")
    } else {
      navigate("/career")
    }
  }, [i18n.language])

  const handleConfirmation = () => {
    setConfirmed((prev) => !prev)
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()

    const formData = new FormData()
    formData.append("resume", resume)
    formData.append("email", email)
    formData.append("message", message)
    formData.append("phone", phone)
    formData.append("name", name)
    formData.append("language", i18n.language)

    try {
      // 👇️ const data: CreateUserResponse
      const { data } = await axios.post<any>(`${api}career.php`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept:
            "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        },
      })

      feedbackStore.toggleFeedback()
      feedbackStore.setText(data.message)

      return data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log("error message: ", error.message)
        // 👇️ error: AxiosError<any, any>
        return error.message
      } else {
        console.log("unexpected error: ", error)
        return "An unexpected error occurred"
      }
    }
  }

  const handleFocus = (e: FormEvent | any) => {
    e.preventDefault()

    if (e.type === "focus" && e) {
      e.target.labels[0].style.opacity = "0.1"
    } else if (e.type === "blur" && e) {
      e.target.labels[0].style.opacity = "0.3"
    }
  }

  const handleFileUpload = (e: any) => {
    if (e.target.files?.[0].size > 2097152) {
      alert("File is too big!")
      // TODO reset input
    }
    const selectedFile = e.target.files?.[0]

    setResume(selectedFile)
    setResumeFileName(e.target.value.replace(/^(.*[/\\])?/, "").replace(/(\.[^.]*)$/, ""))
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("titleAndDesc.career.title")}</title>
        <meta name="description" content={`${t("titleAndDesc.career.desc")}`}></meta>
        <link rel="canonical" href={`${t("titleAndDesc.career.canonical")}`} />
      </Helmet>
      <main className={s.career}>
        <section className={s.intro}>
          <div className={s.bg}></div>
          <h1 className={s.title}>
            {t("career.title.p1")} <span className={s.italic}>{t("career.title.p2")}</span>
          </h1>
          <div className={s.bottom}>
            <div className={s.imgW}>
              <Img src={charCareer} objectFit="contain"></Img>
            </div>
            <div className={s.descW}>
              <p className={s.desc}>{t("career.desc")}</p>
            </div>
          </div>
        </section>
        <div className={s.careerForm}>
          <form className={s.form} ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data">
            <div className={s.col}>
              <div className={s.row}>
                <div className={cx(s.inputW, s.name)}>
                  <small className={s.small}>{t("career.form.personalInfo")}</small>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: name,
                    })}
                    htmlFor="name"
                  >
                    {t("career.form.name")}
                  </label>
                  <input
                    className={s.input}
                    id="name"
                    type="text"
                    ref={nameInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setName(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>

              <div className={s.row}>
                <div className={s.inputW}>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: phone,
                    })}
                    htmlFor="phone"
                  >
                    {t("career.form.phone")}
                  </label>
                  <input
                    className={s.input}
                    id="phone"
                    type="tel"
                    ref={phoneInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setPhone(e.currentTarget.value)
                    }}
                    onInput={(e: any) =>
                      (e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"))
                    }
                  />
                </div>

                <div className={s.inputW}>
                  <label
                    className={cx(s.label, {
                      [s.hidden]: email,
                    })}
                    htmlFor="email"
                  >
                    {t("career.form.email")}
                  </label>
                  <input
                    className={s.input}
                    id="email"
                    type="email"
                    ref={emailInputRef}
                    onFocus={handleFocus}
                    onBlur={handleFocus}
                    onChange={(e) => {
                      setEmail(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>

              <div className={s.row}>
                <div className={s.textareaW}>
                  <label className={s.label} htmlFor="message">
                    {t("career.form.message")}
                  </label>
                  <textarea
                    className={s.textarea}
                    id="message"
                    ref={messageInputRef}
                    onChange={(e) => {
                      setMessage(e.currentTarget.value)
                    }}
                    required
                  />
                </div>
              </div>
            </div>

            <div className={s.col}>
              <div className={s.row}>
                <div className={cx(s.inputW, s.file)}>
                  <label className={cx(s.label)} htmlFor="resume">
                    {resumeFileName ?? t("career.form.file")}
                  </label>
                  <input
                    className={s.input}
                    accept=".pdf,.docx"
                    multiple={false}
                    id="resume"
                    type="file"
                    name="resume"
                    ref={resumeInputRef}
                    onChange={handleFileUpload}
                    required
                  />
                  <small className={s.small}>{t("career.form.fileInfo")}</small>
                  <div className={s.iconW}>
                    <Img src={iconFile} objectFit="contain"></Img>
                  </div>
                </div>
              </div>
              <div className={s.row}>
                <div className={s.confirmation}>
                  <div className={s.checkbox} onClick={handleConfirmation}>
                    <div className={cx(s.inner, { [s.enabled]: confirmed })}></div>
                  </div>
                  <PrivacyPolicy setConfirmation={setConfirmed} path={t("contact.personalDataForm.path")} />
                </div>
              </div>
              <button className={cx(s.sendBtn, { [s.enabled]: confirmed })}>
                <p className={s.btnText}> {t("career.form.btnText")}</p>
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  )
}

export default Career
